import Image from 'atoms/image';

export default function TextImageRow({
  children,
  imageSrc,
  boxShadow = true,
  imgClassName = 'img-vertical',
}) {
  return (
    <>
    <div className="row">
      <div className="col-sm-10 offset-sm-1">
        <div className="row">
          <div className={`col-12 ${imageSrc ? 'col-lg-7' : 'col-lg-9'} children-wrapper`}>
            {children}
          </div>
          {imageSrc && <div className="col-12 col-lg-4 offset-lg-1 text-center image-wrapper">
            <Image boxShadow={boxShadow} className={imgClassName} src={imageSrc} />
          </div>}
        </div>
      </div>
    </div>
    <style jsx>{`
      .children-wrapper {
        position: relative;
        z-index: 1;
      }

      .image-wrapper {
        position: relative;
        z-index: 0;
        max-height: 70vh;
      }
      `}</style>
    </>
  )
}
