import Image from "next/image";

export default function SupportedInsurances({
  insurances = [
    {
      name: "Caresq",
      imageSrc: "/img/insurance_companies/caresq.png"
    },
    {
      name: "CZ",
      imageSrc: "/img/insurance_companies/cz.png"
    },
    {
      name: "Interpolis",
      imageSrc: "/img/insurance_companies/interpolis.png"
    },
    {
      name: "Menzis",
      imageSrc: "/img/insurance_companies/menzis.png"
    },
    {
      name: "VGZ",
      imageSrc: "/img/insurance_companies/vgz.jpeg"
    },
    {
      name: "Zilveren Kruis",
      imageSrc: "/img/insurance_companies/zilveren_kruis.png"    
    },
  ],
}) {
  return (
    <div className="fold-six">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="row basispakket">
              <div className="col-10 offset-1 col-md-6 offset-md-0 text-center text-md-left">
                <h2>Fysio in basispakket? Wij hebben contracten met alle verzekeraars</h2>
              </div>
              <div className="col-10 offset-1 col-md-6 offset-md-0 text-center text-md-left">
                <p>Heb je fysiotherapie in de basisverzekering? Bij Fysiotherapie4all hebben we contracten met alle zorgverzekeraars. Wel kan per verzekering de vergoeding verschillen. Deze is afhankelijk van de klacht en het aantal behandelingen. Tijdens de intake bespreken we wat er mogelijk is.</p>
              </div>
            </div>
            <div className="row insurance-companies text-center">
              {insurances.map(insurance => {
                return (
                  <div key={`insurance-col-${insurance.name}`} className="col-6 col-md-2">
                    <Image
                      className="img-fluid insurance-logo"
                      src={insurance.imageSrc}
                      alt={insurance.name}
                      width={200}
                      height={150}
                      layout="responsive"
                      objectFit="contain"
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .fold-six {
          padding-top: 80px;
        }

        .row.basispakket {
          margin-bottom: 40px;
        }

        .row.basispakket {
          background-color: #f5f5f5;
          padding: 20px;
        }

        .row.basispakket p {
          margin: 0 !important;
        }

        .row.insurance-companies {
          margin-bottom: 80px;
        }
      `}</style>
    </div>
  )
}