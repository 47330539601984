import Button from 'atoms/button';
import Image from 'atoms/image';

export default function HomepageFindPhsyio() {
  return (
    <div className="container">
      <div className="row afspraak justify-content-center">
        <div className="col-0 col-lg-2" />
        <div className="col-10 col-md-6 col-lg-4 text-center text-md-right">
          <h2>Altijd een fysio bij jou in de buurt</h2>
          <Button link="/locaties">Zoek een fysiotherapeut in de buurt <i className="fas fa-map-marker-alt"></i></Button>
          <p className="mt-20">Op basis van een digitaal intake gesprek maken we een plan en brengen we je in contact met de juiste fysiotherapeut bij jou in de buurt.</p>
        </div>
        <div className="col-10 col-md-6 col-lg-4">
          <Image
            boxShadow
            src="/img/map.jpg"
            width={420}
            height={350}
            layout="responsive"
            optimized
          />
        </div>
      </div>
    </div>
  )
}