import Button from 'atoms/button';
import Image from 'atoms/image';
import handleHrefClick from 'lib/handle-href-click';
import Link from 'next/link';
import { Container, Row } from 'react-bootstrap';

function renderSpecialismItem(specialism) {
  return (
    <li key={`specialism-item-${specialism.link}`}>
      <Link onClick={handleHrefClick} href={specialism.link}>
        <a className="text-white">{specialism.label}</a>
      </Link>
      <style jsx>{`
        li {
          margin-bottom: 20px;
        }
      `}</style>
    </li>
  )
}

export default function HomepageSpecialties({
  specialisms
}) {
  return (
    <div className="fold-nine">
      <Container>
        <Row>
          <div className="col-md-9 col-lg-8 offset-lg-1  text-center text-md-left">
            <h2 className="text-white text-center text-md-left">De specialisaties van onze fysiotherapeuten</h2>
            <p className="text-white text-center text-md-left">Om iedere klacht gericht te kunnen behandelen, beheersen wij diverse specialisaties binnen ons vak, maar ook aanverwante specialisaties. </p>
            <Row>
              <div className="col-md text-center text-md-left margin-sm-0 margin-xs-0">
                <ul className="text-white fancy-bullets">
                  {specialisms.slice(0, 3).map(renderSpecialismItem)}
                </ul>
              </div>
              <div className="col-md text-center text-md-left margin-sm-0 margin-xs-0">
                <ul className="text-white fancy-bullets">
                  {specialisms.slice(3, 6).map(renderSpecialismItem)}
                </ul>
              </div>
              <div className="col-md text-center text-md-left margin-sm-0 margin-xs-0">
                <ul className="text-white fancy-bullets">
                  {specialisms.slice(6, 9).map(renderSpecialismItem)}
                </ul>
              </div>
            </Row>
            <Button link="/specialismen">Lees meer over onze specialisaties</Button>
          </div>
        </Row>
      </Container>

      <style jsx>{`
        .fold-nine {
          background-color: rgb(22,168,244);
          //background: url('/img/backgrounds/fold-nine-circles-bg.svg'), url('/img/backgrounds/fold-nine-bg.svg');
          //background-size: 125% 70%;
          //background-repeat: no-repeat;
          //background-position: -60px 0;
          //padding: 200px 0 70px 0;
          //margin-top: -150px;
        }

        .fold-nine img {
          //margin-top: -80% !important;
        }

        .fold-nine ul {
          list-style-type: none;
        }

        .fold-nine li {
          margin-bottom: 20px;
        }

        // @media screen and (max-width: 543px) {
        //   .fold-nine {
        //     background-size: cover;
        //     background-position: center 0;
        //     padding: 220px 0 120px 0;
        //     margin-top: -190px;
        //   }
        // }

        // @media screen and (min-width: 544px) and (max-width: 767px) {
        //   .fold-nine {
        //     background-size: 265% 100%;
        //     padding: 250px 0 60px 0;
        //     background-position: -140px 0;
        //   }
        // }
        
        // @media screen and (min-width: 992px) and (max-width: 1199px) {
        //   .fold-nine {
        //       background-size: 165% 80%;
        //       margin-top: 0;
        //   }
        // }

        // @media screen and (min-width: 767px) and (max-width: 991px) {
        //   .fold-nine {
        //       background-size: 165% 100%;
        //       padding: 200px 0 60px 0;
        //       margin-top: -75px;
        //   }

        //   .fold-nine img.img-vertical {
        //       height: auto;
        //       margin-top: 200% !important;
        //       width: 300px;
        //   }
        // }
      `}</style>
    </div>
  )
}