import Hero from 'components/folds/homepage-hero'
import WhyFysio4All from 'components/folds/homepage-why-fysio4all'
import AboutPhysio from 'components/folds/homepage-about-physio'
import OurMethods from 'components/folds/homepage-our-methods'
import SupportedInsurances from 'components/folds/common/supported-insurances'
import FindPhysio from 'components/folds/homepage-find-physio'
import OnlineAppointment from 'components/folds/homepage-online-appointment'
import WhatIsPhysio from 'components/folds/homepage-what-is-physio'
import TreatedComplaints from 'components/folds/homepage-treated-complaints'
import Specialties from 'components/folds/homepage-specialties'
import Socials from 'components/folds/homepage-socials'
import { fetchAllPostsOfType } from 'lib/wp-fetcher'
import ReviewsFold from 'components/folds/common/reviews-fold'
import PijncheckFold from 'components/folds/common/pijncheck-fold'
import { fetchRecentReviews, fetchReviewsSummary } from 'lib/feedbackcompany-fetcher'

export default function Home({
  specialisms,
  reviewData,
}) {
  return (
    <main>
      <Hero />     
      <ReviewsFold
        reviews={reviewData.reviews}
        summary={reviewData.summary}
      />
      <WhyFysio4All />
      <AboutPhysio />
      <PijncheckFold />
      <OurMethods />
      <SupportedInsurances />
      <FindPhysio />
      <OnlineAppointment />
      <WhatIsPhysio />
      <TreatedComplaints />
      <Specialties specialisms={specialisms} />
      <Socials />
    </main>
  )
}

export async function getStaticProps() {
  const specialismPosts = await fetchAllPostsOfType('specialisms')
  const specialisms = specialismPosts.map(specialismPost => ({
    link: new URL(specialismPost.link).pathname,
    label: specialismPost.title.rendered,
  }))

  return {
    props: {
      specialisms,
      reviewData: {
        reviews: await fetchRecentReviews(),
        summary: await fetchReviewsSummary(),
      },
    }
  }
}
