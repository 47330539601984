import Button from 'atoms/button';
import Image from 'atoms/image';

export default function HomepageTreatedComplaints({
  complaints = [
    {
      imageSrc: '/img/icons/elbow-pain.svg',
      link: '/elleboogklachten',
      label: 'Elleboogklachten',
    },
    {
      imageSrc: '/img/icons/shoulder-pain.svg',
      link: '/schouderklachten',
      label: 'Schouderklachten',
    },
    {
      imageSrc: '/img/icons/knee-pain.svg',
      link: '/knieklachten',
      label: 'Knieklachten',
    },
    {
      imageSrc: '/img/icons/pelvis-pain.svg',
      link: '/heupklachten',
      label: 'Heupklachten',
    },
    {
      imageSrc: '/img/icons/wrist-pain.svg',
      link: '/chronische-pijn',
      label: 'Chronische pijn',
    },
    {
      imageSrc: '/img/icons/ankle-pain.svg',
      link: '/enkelklachten',
      label: 'Enkelklachten',
    },
    {
      imageSrc: '/img/icons/neck-pain.svg',
      link: '/nekklachten',
      label: 'Nekklachten',
    },
    {
      imageSrc: '/img/icons/back-pain.svg',
      link: '/rugklachten',
      label: 'Rugklachten',
    },
    {
      imageSrc: '/img/icons/headache.svg',
      link: '/hoofdpijnklachten',
      label: 'Hoofdpijnklachten',
    },
  ]
}) {
  return (
    <div className="fold-eight">
      <span className="anchor-100-px" id="veel-voorkomende-klachten" />
      <div className="container">
        <div className="row">
          <div className="col-lg-4 text-center text-lg-left">
            <h2>Welke veel voorkomende klachten behandelen wij?</h2>
            <p>Zoals je hierboven hebt kunnen lezen, zijn er uiteenlopende aanleidingen om een bezoek te brengen aan een fysiotherapeut. In grote lijnen zijn de voorkomende klachten in te delen in de volgende categorieën. Soms is de aanleiding voor je klacht heel duidelijk, zoals een ongeval of ziekte.</p>
            <p>Soms heb je de hulp nodig van je fysio om er samen achter te komen wat de aanleiding is en op die manier herhaling van de klachten in de toekomst te voorkomen. Wat jouw verhaal ook is, onze fysiotherapeuten helpen je aan de oplossing voor je klachten. Onze missie is om iedereen te verbinden met een fysiotherapie in de buurt. </p>
          </div>
          <div className="col-lg-8 text-center">
            <div className="row">
              {complaints.map(complaint => {
                return (
                  <div key={`${complaint.link}-${complaint.label}`} className="col-12 col-sm-4">
                    <Image className="icon" src={complaint.imageSrc} width={170} height={170} />
                    <p className="icon-text">
                      <Button link={complaint.link}>{complaint.label}</Button>
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .fold-eight {
          margin-top: 60px;
        }

        .icon-text {
          border-radius: 5px;
          font-weight: 700;
          z-index: 1;
          position: relative;
          max-width: 200px;
          margin: -50px auto 50px auto;
        }

        .icon-text .btn-primary {
          width: 170px;
        }
      `}</style>
    </div>
  )
}