import Button from 'atoms/button';
import Image from 'atoms/image';

export default function HomepageOnlineAppointment() {
  return (
    <div className="fold-six">
      <div className="container">
        <div className="row afspraak justify-content-center">
          <div className="col-10 col-md-6 col-lg-4 mb-5 text-lg-right">
            <Image
              boxShadow
              src="/img/schedule-book.jpg"
              width={450}
              height={450}
              layout="responsive"
              optimized
            />
          </div>
          <div className="col-10 col-md-6 text-center text-md-left">
            <h2>Maak online je eerste afspraak</h2>
            <div className="row">
              <div className="col-lg-8">
                <Button link="/locaties">Plan je eerste afspraak  <i className="fas fa-calendar-day"></i></Button>
                <p className="mt-20">Een fysiotherapiebehandeling duurt meestal een half uur. De intake, je eerste afspraak, kan echter een half uur tot een uur duren. Maak via ons online contactformulier je eerste afspraak en ervaar wat mogelijk is binnen de fysiotherapie met jouw klachten.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .fold-six {
        }
        
        .row.afspraak {
          margin-bottom: 80px;
        }

        .row.afspraak .btn {
          margin-bottom: 20px;
        }
      `}</style>
    </div>
  )
}