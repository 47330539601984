import Image from 'atoms/image';

export default function HomepageAboutPhysio() {
  return (
    <div className="fold-four">
      <div className="container">
        <div className="row">
          <div className="col-sm-10 offset-sm-1">
            <h2 className="d-block d-sm-block d-md-none text-center">Over onze fysiotherapie</h2>
            <div className="row over-ons">
              <div className="col-sm-7 col-md-8">
                <h2 className="d-none d-md-block d-lg-block">Over onze fysiotherapie</h2>
                <p>Persoonlijke benadering staat centraal bij Fysiotherapie4all. Daarom bieden we je aan om eerst vrijblijvend een digitale afspraak te maken met een fysiotherapeut waarin we luisteren naar jouw klachten. Op basis van dit gesprek maken we samen een plan.<br /><br />We kijken naar de klacht, maar ook naar jou als persoon. Word je door jouw klacht bijvoorbeeld beperkt in werk of sport? Dan spelen we hier zo goed mogelijk op in. Ook kijken we hoe we dingen in jouw dagelijks leven kunnen aanpassen, zodat je minder kans hebt dat de klacht terugkomt. Daarna verbinden we je met een fysiotherapeut in de buurt die jou het best verder kan helpen. Deze digitale afspraak functioneert dus als een intake waarna we je kunnen doorverwijzen.</p>
              </div>
              <div className="col-8 offset-2 col-sm-5 col-md-4 offset-sm-0">
                <div className="mb-4">
                  <Image
                    optimized
                    width={287}
                    height={191}
                    layout="responsive"
                    boxShadow
                    src="/img/man-training-running.jpg"
                  />
                </div>
                <div className="mb-4">
                  <Image
                    optimized
                    width={287}
                    height={191}
                    layout="responsive"
                    boxShadow
                    src="/img/man-performing-manual-therapy.jpg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .fold-four {
        }

        @media screen and (min-width: 544px) and (max-width: 767px) {
          .fold-four {
              margin-top: 30px;
          }

          .row.over-ons {
              align-items: flex-end;
          }
        }

        @media screen and (max-width: 543px) {
          .fold-four {
              margin-top: 30px;
          }
        }
      `}</style>
    </div>
  )
}