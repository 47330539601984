import NextImage from 'next/image'
import Image from 'atoms/image'
import { Col, Row } from 'react-bootstrap'

export default function HomepageWhyFysio4all({
  items = [
    {
      imageSrc: "/img/icons/chiropractic.svg",
      text: "Gespecialiseerde fysiotherapie in de buurt voor iedere pijnklacht",
    },
    {
      imageSrc: "/img/icons/schedule.svg",
      text: "Boek direct een digitale afspraak wanneer het jou uit komt, ook in de avond en het weekend mogelijk",
    },
    {
      imageSrc: "/img/icons/health-insurance.svg",
      text: "Fysio in basispakket? Wij hebben contracten met alle verzekeraars",
    },
    {
      imageSrc: "/img/icons/care.svg",
      text: "Naast een digitale afspraak nog veel meer online hulp dankzij een gratis webinar en info op social media",
    },
    {
      imageSrc: "/img/icons/rating.svg",
      text: "Klanten van Fysiotherapie4all geven ons gemiddeld een 9,1!",
    },
  ],
}) {
  return (
    <div className="fold-three">
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <h2 className="mbm">Waarom Fysiotherapie4All?</h2>
          </div>
        </div>
        <Row className="justify-content-center">
          <Col sm={10}>
            <Row className="why justify-content-center">
              {items.map((item, index) => {
                return (
                  <Col key={`why-fysio4all-item-${index}`} sm={6} md={4} className="mb-5">
                    <div className="box text-center background-white rounded-corner">
                      <div className="img-container mb-4">
                        <NextImage
                          layout="responsive"
                          width={75}
                          height={75}
                          objectFit="contain"
                          alt=""
                          src={item.imageSrc}
                        />
                      </div>
                      <p className="text-bold">{item.text}</p>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
      </div>

      <style jsx>{`
        .fold-three {
          //background: url('/img/backgrounds/fold-three-bg.svg');
          background-color: #f5f5f5;
          //background-size: 100% 75%;
          //background-repeat: no-repeat;
          //background-position: fixed;
          //padding-top: 280px;
          //margin-top: -50px;
          color: #333333;
        }

        .mbm {
          margin-bottom: -50px;
        }

        .row.why {
          margin-bottom: 40px;
        }

        .row.why > div {
          margin-bottom: 30px;
        }

        .row.why img {
          max-width: 75px;
          max-height: 75px;
          border-radius: unset;
          box-shadow: none;
          margin-left: auto;
          margin-right: auto;
        }

        .img-container {
          max-width: 75px;
          max-height: 75px;
          border-radius: unset;
          box-shadow: none;
          margin-left: auto;
          margin-right: auto;
        }

        // @media screen and (min-width: 767px) and (max-width: 992px) {
        //   .fold-three h2 {
        //     padding-top: 40px;
        //   }
        // }

        // @media screen and (min-width: 544px) and (max-width: 767px) {
        //   .fold-three {
        //       background-size: cover;
        //       background-position: right 0;
        //       padding-top: 270px;
        //       margin-top: -100px;
        //   }
        // }

        // @media screen and (max-width: 543px) {
        //   .fold-three {
        //       background-size: cover;
        //       background-position: right 0;
        //       padding-top: 150px;
        //       margin-top: -100px;
        //   }
        // }
      `}</style>
    </div>
  )
}