import Image from 'atoms/image';
import handleHrefClick from 'lib/handle-href-click';

export default function HomepageSocials() {
  return (
    <div className="fold-ten">
      <div className="container">
        <div className="row">
          <div className="col-md-12 offset-md-1">
            <div className="row align-items-end">
              <div className="col-md-10 col-lg-6">
                <h2>Volg Fysiotherapie4all</h2>
                <p>Wij zijn zeer actief op Youtube, Facebook en Instagram. Op deze kanalen kun je veel informatie vinden over allerlei fysieke klachten en hoe je deze kunt bestrijden en zelfs voorkomen. </p>
                <ul className="fancy-bullets">
                  <li><a onClick={handleHrefClick} href="/wiki"><strong>Bekijk ook onze wiki</strong></a></li>
                </ul>
              </div>
              <div className="col-3 col-md-3 col-lg-1">
                <a
                  onClick={handleHrefClick}
                  href="https://www.facebook.com/Fysiotherapie4all/"
                  target="_blank"
                  rel="noopener"
                >
                  <Image className="icon social" src="/img/icons/facebook.svg" alt="Fysiotherapie4all Facebook pagina" width={75} height={75} optimized layout="responsive" objectFit="contain" />
                </a>
              </div>
              <div className="col-3 col-md-3 col-lg-1">
                <a
                  onClick={handleHrefClick}
                  href="https://www.youtube.com/channel/UCaiWS97VXzxsJb79LsE_nZQ"
                  target="_blank"
                  rel="noopener"
                >
                  <Image className="icon social" src="/img/icons/youtube.svg" alt="Fysiotherapie4all YouTube kanaal" width={75} height={75} optimized layout="responsive" objectFit="contain" />
                </a>
              </div>
              <div className="col-3 col-md-3 col-lg-1">
                <a
                  onClick={handleHrefClick}
                  href="https://www.instagram.com/fysiotherapie4all/"
                  target="_blank"
                  rel="noopener"
                >
                  <Image className="icon social" src="/img/icons/instagram.svg" alt="Fysiotherapie4all Instagram pagina" width={75} height={75} optimized layout="responsive" objectFit="contain" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .fold-ten ul {
          list-style-type: none;
        }

        .fold-ten li {
          margin-bottom: 20px;
        }
      `}</style>
    </div>
  )
}