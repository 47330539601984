import TextImageRow from 'components/text-image-row';
import handleHrefClick from 'lib/handle-href-click';

export default function HomepageWhatIsPhysio() {
  return (
    <div className="fold-seven">
			<div className="container">
				<TextImageRow
          imageSrc="/img/massage.jpg"
        >
          <h2>Wat is fysiotherapie?</h2>
          <p>Fysiotherapie, in België ook vaak kinesitherapie genoemd, is bedoeld voor mensen met klachten die te maken hebben met de houding of het bewegingsapparaat. Denk hierbij aan te gespannen spieren, <a onClick={handleHrefClick} href="/peesontsteking">peesontstekingen</a>, bandpijn, <a onClick={handleHrefClick} href="https://www.fysiotherapie4all.nl/artrose">artrose</a>, <a onClick={handleHrefClick} href="https://www.fysiotherapie4all.nl/slijmbeursontsteking">slijmbeursontsteking</a>, <a onClick={handleHrefClick} href="https://www.fysiotherapie4all.nl/spierscheuring">spierscheuring</a>, <a onClick={handleHrefClick} href="https://www.fysiotherapie4all.nl/klachten/aandoeningen/item/ischias">ischias</a> of <a onClick={handleHrefClick} href="https://www.fysiotherapie4all.nl/achillespeesklachten/">achillespeesklachten</a>. Fysiotherapie kan ook helpen bij de gevolgen van functiestoornissen aan het zenuwstelsel, de bloedvaten, de longen, het hart en de huid, zoals de gevolgen van M.S., een beroerte, Cara, een hartinfarct en verklevingen. Maar ook bij <a onClick={handleHrefClick} href="https://www.fysiotherapie4all.nl/revalidatie-na-een-corona-covid-19-infectie">revalidatie na het Corona-virus</a>, waar momenteel veel mensen door getroffen worden en lang van moeten herstellen.</p>
          <p>Klachten kunnen ook ontstaan tijdens het uitoefenen van werkzaamheden, een ongeluk, spanning of als gevolg van herhaaldelijke overbelasting of ouderdom. Met behulp van fysiotherapie en kinesitherapie oefeningen stabiliseren we de functiestoornissen en verminderen of verhelpen we de (pijn)klachten. </p>
        </TextImageRow>
			</div>

      <style jsx>{`
        .fold-seven {
          background-color: #f5f5f5;
          //background: url('/img/backgrounds/fold-seven-circles-bg.svg'), url('/img/backgrounds/fold-seven-bg.svg');
          //background-size: 132% 85%;
          //background-repeat: no-repeat;
          //background-position: -50px 0;
          //padding: 120px 0;
          //margin-bottom: -500px;
        }

        // @media screen and (min-width: 992px) and (max-width: 1199px) {
        //   .fold-seven {
        //     background-size: 150% 80%;
        //   }
        // }

        // @media screen and (min-width: 767px) and (max-width: 991px) {
        //   .fold-seven {
        //     background-size: 200% 90%;
        //     padding: 150px 0 120px 0;
        //   }
        // }

        // @media screen and (min-width: 544px) and (max-width: 767px) {
        //   .fold-seven {
        //     background-size: 400% 70%;
        //     margin-bottom: -400px;
        //   }
        // }

        // @media screen and (max-width: 543px) {
        //   .fold-seven {
        //     background-size: 400% 90%;
        //     background-position: -100px -70px;
        //     padding: 70px 0 0 0;
        //     margin-bottom: -400px;
        //   }
        // }
      `}</style>
		</div>
  )
}
