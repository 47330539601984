import Image from 'atoms/image';

export default function HomepageOurMethods() {
  return (
    <div className="fold-five">
      <div className="container">
        <div className="row">
          <div className="d-none d-lg-block col-lg-5 text-center text-lg-left">
            <h2>Hoe gaan onze fysiotherapeuten te werk?</h2>
            <p>Wanneer je een bepaald doel wilt bereiken, moet je een goed doordacht plan hebben. Wij hebben binnen onze visie een stappenplan opgesteld om jou op een doelgerichte manier pijnvrij te krijgen.</p>
          </div>
        </div>
        <div className="row hoe-werkt-het justify-content-center">
          <div className="mb-5 col-md-4">
            <div className="box text-center background-white rounded-corner">
              <Image className="no-radius" src="/img/icons/loupe.svg" width={70} height={70} optimized layout="fixed" objectFit="contain" />
              <p className="text-bold">Stap 1: Aandacht voor jouw probleem</p>
              <p>Tijdens een uitgebreid gesprek brengen we jouw probleem zorgvuldig in kaart.</p>
            </div>
          </div>
          <div className="mb-5 col-md-4">
            <div className="box text-center background-white rounded-corner">
              <Image className="no-radius" src="/img/icons/physiotherapy.svg" width={70} height={70} optimized layout="fixed" objectFit="contain" />
              <p className="text-bold">Stap 2: Uitgebreid</p>
              <p>We voeren een inspectie uit, gecombineerd met een actief en passief functieonderzoek. Hierin komen we zwakke schakels tegen, die we meenemen in het behandelprotocol.</p>
            </div>
          </div>
          <div className="mb-5 col-md-4">
            <div className="box text-center background-white rounded-corner">
              <Image className="no-radius" src="/img/icons/rehabilitation.svg" width={70} height={70} optimized layout="fixed" objectFit="contain" />
              <p className="text-bold">Stap 3: De keuze van de therapie</p>
              <p>Jouw pijnklacht hangt vrijwel altijd samen met een storing in een functie. Deze gaan we oplossen middels bewezen therapieën. Wij behandelen direct de structuren die niet goed functioneren, met als doel het lichaam weer in balans te brengen.</p>
            </div>
          </div>
          <div className="mb-5 col-md-4">
            <div className="box text-center background-white rounded-corner">
              <Image className="no-radius" src="/img/icons/healthcare-and-medical.svg" width={70} height={70} optimized layout="fixed" objectFit="contain" />
              <p className="text-bold">Stap 4: Testen & meten</p>
              <p>Na de behandeling testen we de functie opnieuw. Zo weten wij zeker of de dynamiek hersteld is en jij hebt het bewijs dat de technieken die wij gebruiken voor jou werken.</p>
            </div>
          </div>
          <div className="mb-5 col-md-4">
            <div className="box text-center background-white rounded-corner">
              <Image className="no-radius" src="/img/icons/healthcare-and-medical-upright.svg" width={70} height={70} optimized layout="fixed" objectFit="contain" />
              <p className="text-bold">Stap 5: Evaluatie & nazorg</p>
              <p>Wij streven naar een duurzame oplossing. Dit houdt in dat we niet voor een zogenoemde quickfix gaan, maar dat we klachten blijvend willen oplossen en dus voorkomen dat ze terugkeren. Dit doen we door middel van adviezen op het gebied van beweging, mindset en/of voeding.</p>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .fold-five {
          //background: url('/img/backgrounds/fold-five-cirlces-bg.svg'), url('/img/backgrounds/fold-five-bg.svg');
          //background: url('/img/backgrounds/fold-five-bg.svg');
          // background-size: 100% 75%;
          // background-repeat: no-repeat;
          // background-position: left bottom;
          //padding-top: 120px;
        }

        // @media screen and (min-width: 767px) and (max-width: 991px) {
        //   .fold-five {
        //       padding-top: 0;
        //       margin-top: -120px;
        //   }
        // }

        // @media screen and (min-width: 544px) and (max-width: 767px) {
        //   .fold-five {
        //       background-size: cover;
        //       padding-top: 0;
        //       margin-top: -80px;
        //   }

        //   .fold-five .container > .row {
        //       padding-top: 0 !important;
        //   }
        // }

        // @media screen and (max-width: 543px) {
        //   .fold-five {
        //       background-size: cover;
        //       padding-top: 0;
        //       margin-top: -80px;
        //   }

        //   .fold-five .container > .row {
        //       padding-top: 0 !important;
        //   }
        // }

        @media screen and (min-width: 992px) {
          .row.hoe-werkt-het {
              align-items: flex-start;
          }

          .row.hoe-werkt-het .col-lg-2 {
              flex: 0 0 20% !important;
              max-width: 20% !important; 
          }

          // .row.hoe-werkt-het .col-lg-2:first-child .box {
          //     margin-top: -200px;
          // }

          // .row.hoe-werkt-het .col-lg-2:nth-child(2) .box,
          // .row.hoe-werkt-het .col-lg-2:nth-child(3) .box {
          //     margin-top: -50px;
          // }

          // .row.hoe-werkt-het .col-lg-2:nth-child(4) .box,
          // .row.hoe-werkt-het .col-lg-2:nth-child(5) .box {
          //     margin-top: 50px;
          // }
        }
      `}</style>
    </div>
  )
}