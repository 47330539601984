import ReactPlayer from 'react-player/lazy';
import { Col, Container, Row } from 'react-bootstrap';

export default function PijncheckFold({ 
}) {
    return (
        <div className="pijncheck-fold">
            <Container>
                <Row className="align-items-center">
                    <Col className="order-2 mt-2 mt-sm-5 mb-2 mb-sm-5 md-md-0 col-12 col-md-5 offset-md-1">
                        <h2 className="text-white">Doe de pijncheck</h2>
                        <p className="text-white">De pijncheck is een online vragenlijst die jou helpt om je klachten in beeld te brengen. Wij geven je gratis online advies die jouw helpt om je klachten op te lossen!</p>

                        <a target="_blank" className="btn btn-primary" href="/pijncheck">Doe de pijncheck</a>
                    </Col>
                    <Col className="order-1 col-12 col-md-6 mt-2 mt-md-0">
                        <div className="player-container">
                            <div className="player-wrapper">
                                <ReactPlayer
                                    url="https://player.vimeo.com/video/754600271"
                                    controls
                                    width="100%"
                                    height="100%"
                                    light="https://i.vimeocdn.com/video/1516189158-cb27ad10b46ec889ad49b125bb48142788b7f6fe827701e43fe6397def95b0f9-d"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <style jsx>{`
                .pijncheck-fold {
                    //background: url(/img/backgrounds/klachten-fold-above-old.svg);
                    //background-color: #f5f5f5;
                    //background-size: 180%;
                    //background-repeat: no-repeat;
                    //background-position: -1440px 100%;
                    background-color: rgb(22,168,244);
                }

                // @media screen and (max-width: 1800px) {
                //     .pijncheck-fold { 
                //         background-size: 250%;
                //     }
                // }

                // @media screen and (max-width: 1050px) {
                //     .pijncheck-fold { 
                //         background-size: 350%;
                //     }
                // }

                // @media screen and (max-width: 770px) {
                //     .pijncheck-fold { 
                //         background-size: 450%;
                //         margin-bottom: 100px;
                //     }
                // }

                .pijncheck-fold h2, .pijncheck-fold p {
                    //color: #fff;
                }

                .player-container {
                    padding-top: 56.25%;
                    position: relative;
                }
          
                .player-wrapper {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                }
            `}</style>
        </div>
    )
}